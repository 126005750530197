import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { ThemeService } from '../theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private themeService: ThemeService) { }

  public showAlert(title: string = '', text: string, icon: SweetAlertIcon = 'success'): Promise<SweetAlertResult> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'custom-swal-popup border-4',
        confirmButton: 'px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-500',
        cancelButton: 'px-4 py-2 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'Entendido',
      background: this.themeService.isDarkMode() ? '#101825' : '#FBFCFC',
      color: this.themeService.isDarkMode() ? '#FBFCFC' : '#101825',
      buttonsStyling: false,
    });
  }

  public showConfirm(title: string, text: string, icon: SweetAlertIcon): Promise<SweetAlertResult> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'custom-swal-popup border-4',
        confirmButton: 'px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-500 mr-2',
        cancelButton: 'px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-500',
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
      background: this.themeService.isDarkMode() ? '#1f2937' : '#f5f5f5 ',
      color: this.themeService.isDarkMode() ? '#f5f5f5 ' : '#1f2937',
      buttonsStyling: false,
    });
  }
}
